import React from "react";
import moment from "moment";
import AppCard from "../../../../components/core/AppCard";

const Post = (props) => {
  const { data } = props;
  return (
    <AppCard className="mb-3">
      <div className="p-3">
        <h4>
          <b>{data.title.toUpperCase()}</b>{" "}
        </h4>
        <p style={{ color: "#333" }}>
          <div dangerouslySetInnerHTML={{ __html: data.brief }}></div>
        </p>
        <div class="d-flex flex-row">
          <div style={{ color: "#707070" }} class="p-2">
            Autor: {data?.user}
          </div>
          <div style={{ color: "#707070" }} class="p-2">
            {moment(data.updatedAt).format("DD-MM-YYYY")}
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default Post;
