import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Select } from "antd";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Page from "../../../components/Page/Page";
import Header from "./components/Header";
import { makeStyles } from "@material-ui/styles";
import TableQuestions from "./components/TableQuestions";
import useForm from "react-hook-form";
import ilustradozApi from "../../../api/ilustradozApi";

const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

const CreateQuestions = (props) => {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm({});
  const [schools, setSchools] = useState([]);
  const [visible, setVisible] = useState(false);
  const [challenge, setChallenge] = useState();
  const [update, setupdate] = useState(null);
  const [topic, setTopic] = useState(null);
  const [school, setSchool] = useState(null);
  const [course, setCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [topics, setTopics] = useState([]);
  const [contentQuestion, setContentQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [index, setIndex] = useState(0);

  const onSubmit = async (e, event) => {
    console.log(e, event);
    // let formData = new FormData();
    // formData.append("answer", e.correctAnswer);
    // formData.append("contentQuestion", contentQuestion);
    // formData.append("alternative1", e.alternative1);
    // formData.append("alternative2", e.alternative2);
    // formData.append("alternative3", e.alternative3);
    // formData.append("alternative4", e.alternative4);
    // formData.append("topic", topic);
    // formData.append("course", course);
    // formData.append("school", school);

    // if (contentQuestion) {
    //   const res = await ilustradozApi.post(`/academic/question`, formData);
    //   if (res.status === 200) {
    //     event.target.reset();
    //     setContentQuestion("");
    //   }
    // }

    // convert e.json to array of objects
    const json = eval(e.json);

    for (const question of json) {
      console.log(question);
      let formData = new FormData();
      formData.append(
        "answer",
        question.multipleChoice.find((e) => e.correct == true).answer
      );
      formData.append("contentQuestion", question.contentQuestion);
      formData.append("alternative1", question.multipleChoice[0].answer);
      formData.append("alternative2", question.multipleChoice[1].answer);
      formData.append("alternative3", question.multipleChoice[2].answer);
      formData.append("alternative4", question.multipleChoice[3].answer);
      formData.append("topic", question.topic);
      formData.append("course", question.course);
      formData.append("school", question.school);
      const res = await ilustradozApi.post(`/academic/question`, formData);
      
    }
  };

  const deleteQuestion = async (id_question) => {
    let res = await ilustradozApi.delete(`/challenge/${id_question}/`);
    setVisible(false);
  };

  function handleCancel(e) {
    setVisible(false);
  }
  const showModalDelete = (id) => {
    setVisible(true);
    setChallenge(id);
  };

  return (
    <Page className={classes.root}>
      <Header
        title="crear contenido"
        subtitle="MIS RETOS"
        setupdate={setupdate}
      />

      {visible && (
        <Modal
          onOk={() => deleteQuestion(challenge)}
          onCancel={handleCancel}
          title="Eliminar"
          visible={visible}
        >
          <p>¿Seguro que desea eliminar la pregunta?</p>
        </Modal>
      )}
      <div className="mt-3">
        <Card>
          <CardContent>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <h3>INGRESAR PREGUNTAS</h3>
                <textarea
                  class="form-control"
                  name="json"
                  rows="10"
                  ref={register({
                    required: "Requerido",
                  })}
                ></textarea>
                <br />
                <div className="row">
                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-primary">
                      Crear Pregunta
                    </button>
                  </div>
                </div>
              </form>
            </>
          </CardContent>{" "}
        </Card>
        <br />
        <CardContent>
          <TableQuestions update={showModalDelete} questions={questions} />
        </CardContent>
      </div>
    </Page>
  );
};

export default CreateQuestions;
