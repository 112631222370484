/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from "react";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import ViewModuleIcon from "@material-ui/icons/ViewModule";

import Label from "../../../Label";

export default [
  {
    title: "",
    pages: [
      {
        title: "Inicio",
        href: "/",
        icon: HomeIcon,
      },
      {
        title: "Aprender",
        href: "/aprender",
        icon: DashboardIcon,
        children: [
          {
            title: "Aprender",
            href: "/learn",
          },
          {
            title: "Retos",
            href: "/reto",
          },
        ],
      },
      {
        title: "Crear contenido",
        href: "/vouchers",
        icon: DashboardIcon,
        children: [
          {
            title: "Mis retos",
            href: "/mychallenges",
          },
          {
            title: "Mis bloques",
            href: "/myblocks",
          },
        ],
      },

      {
        title: "Ranking",
        href: "/ranking",
        icon: PeopleIcon,
      },
    ],
  },
  {
    title: "CONFIGURACIÓN",
    pages: [
      {
        title: "Perfil",
        href: "/settings/general",
        icon: ViewModuleIcon,
      },
      {
        title: "Admin",
        href: "/settings/admin/config",
        icon: ViewModuleIcon,
        children: [
          {
            title: "Preguntas",
            href: "/questions",
          },
          {
            title: "Masivo Preguntas",
            href: "/questionsMasivo",
          },
          {
            title: "Cursos",
            href: "/settings/admin/courses",
          },
          {
            title: "Temas",
            href: "/settings/admin/topics",
          },
          {
            title: "Blog",
            href: "/settings/admin/blog",
          },
          {
            title: "School",
            href: "/settings/admin/school",
          },
          {
            title: "Lecciones",
            href: "/settings/admin/lessons",
          },
        ],
      },
    ],
  },
];
