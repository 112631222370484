import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Select } from "antd";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Page from "../../../components/Page/Page";
import Header from "./components/Header";
import { makeStyles } from "@material-ui/styles";
import TableQuestions from "./components/TableQuestions";
import useForm from "react-hook-form";
import ilustradozApi from "../../../api/ilustradozApi";
import CKEditorAws from "../../../components/CKEditorAws";

const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

const CreateQuestions = (props) => {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm({});
  const [schools, setSchools] = useState([]);
  const [visible, setVisible] = useState(false);
  const [challenge, setChallenge] = useState();
  const [update, setupdate] = useState(null);
  const [topic, setTopic] = useState(null);
  const [school, setSchool] = useState(null);
  const [course, setCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [topics, setTopics] = useState([]);
  const [contentQuestion, setContentQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [index, setIndex] = useState(0);

  const onSubmit = async (e, event) => {
    let formData = new FormData();
    formData.append("answer", e.correctAnswer);
    formData.append("contentQuestion", contentQuestion);
    formData.append("alternative1", e.alternative1);
    formData.append("alternative2", e.alternative2);
    formData.append("alternative3", e.alternative3);
    formData.append("alternative4", e.alternative4);
    formData.append("topic", topic);
    formData.append("course", course);
    formData.append("school", school);

    if (contentQuestion) {
      const res = await ilustradozApi.post(`/academic/question`, formData);
      if (res.status === 200) {
        getQuestions();
        event.target.reset();
        setContentQuestion("");
      }
    }
  };

  const deleteQuestion = async (id_question) => {
    let res = await ilustradozApi.delete(`/challenge/${id_question}/`);
    setVisible(false);
    getQuestions();
  };

  useEffect(() => {
    getSchools();
  }, []);

  useEffect(() => {
    if (course != null) {
      getTopics();
    }

    if (school !== null) {
      getCourses();
    }
  }, [course, school]);

  useEffect(() => {
    if (topic) {
      getQuestions();
    }
  }, [topic]);

  const getCourses = async () => {
    const res = await ilustradozApi.get(`/academic/courses/${school}`);
    if (res.status === 200) {
      setCourses(res.data);
    }
  };

  const getSchools = async () => {
    const res = await ilustradozApi.get(`/academic/schools`);
    if (res.status === 200) {
      setSchools(res.data);
    }
  };

  const getTopics = async () => {
    const res = await ilustradozApi.get(`/academic/topic/${course}`);
    if (res.status === 200) {
      setTopics(res.data);
    }
  };

  const getQuestions = async () => {
    const res = await ilustradozApi.get(`/academic/questions/${topic}`);
    if (res.status === 200) {
      setQuestions(res.data);
    }
  };

  function handleCancel(e) {
    setVisible(false);
  }
  const showModalDelete = (id) => {
    setVisible(true);
    setChallenge(id);
  };

  return (
    <Page className={classes.root}>
      <Header
        title="crear contenido"
        subtitle="MIS RETOS"
        setupdate={setupdate}
      />

      {visible && (
        <Modal
          onOk={() => deleteQuestion(challenge)}
          onCancel={handleCancel}
          title="Eliminar"
          visible={visible}
        >
          <p>¿Seguro que desea eliminar la pregunta?</p>
        </Modal>
      )}
      <div className="mt-3">
        <Card>
          <CardContent>
            <label>Selecciona la institucion</label>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={(e) => {
                setSchool(e);
              }}
            >
              {schools.map((option) => (
                <Option key={option._id}>{option.name}</Option>
              ))}
            </Select>
            <br />
            <br />
            <label>Selecciona un curso</label>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={(e) => {
                setCourse(e);
              }}
            >
              {courses.map((option) => (
                <Option key={option._id}>{option.name}</Option>
              ))}
            </Select>
            <br />
            <br />
            <label>Selecciona un tema</label>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={(e) => {
                setTopic(e);
              }}
            >
              {topics.map((option) => (
                <Option key={option._id}>{option.name}</Option>
              ))}
            </Select>
            <br />
            <br />
            {course && topic && (
              <>
                <h3>INGRESAR PREGUNTA</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label>Datos de la pregunta</label>
                    <CKEditorAws
                      data={contentQuestion}
                      setData={setContentQuestion}
                    />
                  </div>
                  <br />
                  <div className="row">
                    {" "}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>Subir imagen</label>
                        <input
                          type="file"
                          className="form-control-file"
                          id="exampleFormControlFile1"
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-6">
                      <label
                        style={{ color: index == 0 ? "#5ceb5c" : "black" }}
                      >
                        Alternativa 1
                      </label>
                      <input
                        className="form-control"
                        name="alternative1"
                        type="text"
                        ref={register({
                          required: "Requerido",
                        })}
                      />
                      <p className="text-danger">
                        {errors.alternative1 && errors.alternative1.message}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-6">
                      <label
                        style={{ color: index == 1 ? "#5ceb5c" : "black" }}
                      >
                        Alternativa 2
                      </label>
                      <input
                        className="form-control"
                        name="alternative2"
                        type="text"
                        ref={register({
                          required: "Requerido",
                        })}
                      />
                      <p className="text-danger">
                        {errors.alternative2 && errors.alternative2.message}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-6">
                      <label
                        style={{ color: index == 2 ? "#5ceb5c" : "black" }}
                      >
                        Alternativa 3
                      </label>
                      <input
                        className="form-control"
                        name="alternative3"
                        type="text"
                        ref={register({
                          required: "Requerido",
                        })}
                      />
                      <p className="text-danger">
                        {errors.alternative3 && errors.alternative3.message}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-6">
                      <label
                        style={{ color: index == 3 ? "#5ceb5c" : "black" }}
                      >
                        Alternativa 4
                      </label>
                      <input
                        className="form-control"
                        name="alternative4"
                        type="text"
                        ref={register({
                          required: "Requerido",
                        })}
                      />
                      <p className="text-danger">
                        {errors.alternative4 && errors.alternative4.message}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-6">
                      <label>Respuesta correcta</label>
                      <select
                        className="form-control"
                        name="correctAnswer"
                        onChange={(e) => {
                          setIndex(e.target.value);
                        }}
                        ref={register({
                          required: "Requerido",
                        })}
                      >
                        <option value={0}>Alternativa 1</option>
                        <option value={1}>Alternativa 2</option>
                        <option value={2}>Alternativa 3</option>
                        <option value={3}>Alternativa 4</option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-12">
                      <button type="submit" className="btn btn-primary">
                        Crear Pregunta
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </CardContent>{" "}
        </Card>
        <br />
        <CardContent>
          <TableQuestions update={showModalDelete} questions={questions} />
        </CardContent>
      </div>
    </Page>
  );
};

export default CreateQuestions;
