import React, { useContext, useEffect, useState } from "react";
import Header from "./components/Header";
import Page from "../../components/Page/Page";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ilustradozApi from "../../api/ilustradozApi";
import LineChart from "../../views/Home/components/Charts/LineChart";
import { AuthContext } from "../../context/AuthContext";
import GeneralStats from "../../views/Home/components/GeneralStats/GeneralStats";
import Axios from "axios";
import AWS from "aws-sdk";
import { Link } from "react-router-dom";

AWS.config.update({
  accessKeyId: "AKIA4VIM3G3XTKLEVW4C",
  secretAccessKey: "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",
  region: "us-east-1",
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const Inicio = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [report, setReport] = useState({});
  const [labels, setlabels] = useState([]);

  async function getReport() {
    let res = await ilustradozApi.get("/challenge/report-exam");
    setData(res.data.data);
    setlabels(res.data.label);
    setReport(res.data);
  }

  useEffect(() => {
    getReport();
  }, []);

  const classes = useStyles();


  const content = (
    <Page className={classes.root}>
      <Header title="INICIO" subtitle={`${user.firstName}`}></Header>
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          <GeneralStats
            stats={{ badgeColor: "#0A8FDC", bgcolor: "#E7F4FB", new: "" }}
            icon="FcGraduationCap"
            title="Puntaje Promedio"
            data={report.average}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <GeneralStats
            stats={{ badgeColor: "#49BD65", bgcolor: "#EAF7EE", new: "" }}
            title="Examenes Semanales"
            data={report.total}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          {/* <TodaysMoney title="Promedio de Correctas" value={user.statsavgCorrect()} /> */}
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          {/* <TodaysMoney title="Promedio de Incorrectas" value={user.statsavgIncorrect()} /> */}
        </Grid>

        <Grid item lg={12} xs={12}>
          {report.total == 0 && (
            <Link to="/reto">
              Parece que aún no tienes exámenes, prueba con uno aquí
            </Link>
          )}
          <LineChart data={data} labels={labels} title="Resumen Semanal" />
        </Grid>

        {/* <Grid item lg={6} xs={12}>
        <LineChart title="4" />
      </Grid>
      <Grid item lg={6} xs={12}>
        <LineChart title="5" />
      </Grid>
      <Grid item lg={6} xs={12}>
        <HorizontalBar title="2"/>
      </Grid>
      <Grid item lg={6} xs={12}>
        <HorizontalBar title="3"/>
      </Grid>
      <Grid item lg={12} xs={12}>
        <VerticalBar title="6"/>
      </Grid> */}
      </Grid>
    </Page>
  );
  return content;
};

export default Inicio;
